.radio-input {
  @apply w-full h-10 relative;
}

.radio-input label,
.radio-input input {
  @apply block absolute inset-0;
}

.radio-input input[type='radio'] {
  @apply z-20 w-full h-full cursor-pointer opacity-0;
}

.radio-input input[type='radio']:checked + label,
.Checked + label {
  @apply bg-yellow-500;
}

.radio-input label {
  @apply z-10 text-sm text-slate-800 border border-slate-300 bg-white rounded flex items-center justify-center cursor-pointer;
}
