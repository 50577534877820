input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  opacity: 1;
}

/* Check animation */
.check-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px rgb(102, 255, 153);
}

.check-pulse {
  animation: check-pulse-animation 1.5s infinite;
}

@keyframes check-pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(102, 255, 153, 0.5);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(102, 255, 153, 0);
  }
}

/* Button animation */
.button-pulse {
  animation: button-pulse-animation 1.5s infinite;
}

@keyframes button-pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(220, 220, 6, 0.7);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(220, 220, 6, 0);
  }
}
